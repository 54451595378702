import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboardLayout/DashboardLayout";
import Home from "./pages/simpleLayoutPages/Home";
import SimpleLayout from "./layouts/simpleLayout/SimpleLayout";
import Dashboard from "./pages/dashboardPages/Dashboard";
import ChapterView from "./pages/dashboardPages/Chapter/ChapterView";
import UserLayout from "./layouts/userLayout/UserLayout";
import AllChaptersPage from "./pages/userLayoutPages/AllChaptersPage";
import EnrolledChaptersPage from "./pages/userLayoutPages/EnrolledChaptersPage";
import ExamViewPage from "./pages/userLayoutPages/ExamViewPage";
import ChapterContent from "./containers/userLayout/ChapterContent/ChapterContent";
import UserProfilePage from "./pages/userLayoutPages/UserProfilePage";
import AdminAllChapters from "./containers/adminLayout/chapters/AllChapters/AdminAllChapters";
import AdminChapterContent from "./containers/adminLayout/chapters/ChapterContent/AdminChapterContent";
import AdminAllStudents from "./containers/adminLayout/students/allStudents/AdminAllStudents";
import AdminStudentContent from "./containers/adminLayout/students/studentContent/AdminStudentContent";
import NotFound from "./pages/NotFound/NotFound";

export default function Router() {
  const isAuthenticated = localStorage.getItem("token");
  const routes = useRoutes([
    {
      path: "",
      element: <SimpleLayout />,
      children: [{ path: "/", element: <Home /> }],
    },
    {
      path: "/dashboard",
      element: <Navigate to="/dashboard/user" replace />,
    },
    {
      path: "/dashboard/admin",
      element: true ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/admin/chapters" replace />,
        },
        { path: "chapters", element: <AdminAllChapters /> },
        { path: "chapters/:chapterID", element: <AdminChapterContent /> },
        { path: "student", element: <AdminAllStudents /> },
        { path: "student/:studentID", element: <AdminStudentContent /> },
      ],
    },
    {
      path: "/dashboard/user",
      element: true ? <UserLayout /> : <Navigate to="/login" replace />,
      children: [
        { path: "profile", element: <UserProfilePage /> },
        { path: "", element: <AllChaptersPage /> },
        { path: "chapters", element: <AllChaptersPage /> },
        { path: "chapters/all", element: <AllChaptersPage /> },
        { path: "chapters/enrolled", element: <EnrolledChaptersPage /> },
        {
          path: "chapters/:chapterID/:openTab?",
          element: <ChapterContent />,
        },
        {
          path: "chapters/:chapterID/exams/:examID",
          element: <ExamViewPage />,
        },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);

  return routes;
}
