import { Link } from "react-router-dom";
import UserLayoutSearchHeader from "../../../components/UserLayout/UserLayoutSearchHeader/UserLayoutSearchHeader";
import "./AllChapters.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { AllChaptersData } from "./AllChaptersData";
import ChapterCard from "../../../components/ChapterCard/ChapterCard";
import DemoPagination from "../../../components/DemoPagination/DemoPagination";
const AllChapters = () => {
  return (
    <section id="user-all-chapters">
      <UserLayoutSearchHeader
        leftSide={
          <span className="units-exams-button-container">
            <span className="units-exam-button-set">
              <Link className={"ec-button-active home-ec-button "}>
                All Chapters
              </Link>
              <Link
                className={"home-ec-button "}
                to="/dashboard/user/chapters/enrolled"
              >
                Enrolled Chapters
              </Link>
            </span>
          </span>
        }
        rightSide={"Search for a chapter"}
      />
      <div className="ul-all-chapters-container">
        {AllChaptersData.map((chapter, index) => {
          return (
            <ChapterCard
              nameEnglish={chapter.chapterEnglish}
              nameTamil={chapter.chapterTamil}
              chapterHeader={"Chapter " + chapter.chapterNumber}
              units={chapter.units}
              exams={chapter.exams}
              key={index}
              image={chapter.image}
              isEnrolled={chapter.isEnrolled}
              id={chapter.id}
            ></ChapterCard>
          );
        })}
      </div>
      <DemoPagination />
    </section>
  );
};

export default AllChapters;
