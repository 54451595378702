import { useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Main from "./main/Main";
import "./DashboardLayout.css";
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";
export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <div className="dashboard-container">
      <Sidebar openNav={open} onCloseNav={() => setOpen(false)} />
      <Main />
    </div>
  );
}
export const editButton = (
  <span style={{ color: "#23758E", cursor: "pointer" }}>
    <FaEdit />
  </span>
);
export const deleteButton = (
  <span style={{ color: "#B91C1C", cursor: "pointer" }}>
    <FaRegTrashAlt />
  </span>
);

export const viewButton = (
  <span style={{ color: "rgb(30 93 38)", cursor: "pointer" }}>
    <MdOpenInNew />
  </span>
);
