import "./ContactUs.css";
import ContactUsImage from "../../../../assets/simpleLayout/contactus/ContactUs.svg";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import CustomTextArea from "../../../../components/FormComponents/TextArea/CustomTextArea";
import SimpleLayoutHeading from "../../../../components/SimpleLayoutHeading.css/SimpleLayoutHeading";
const ContactUs = () => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();

  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "fullName",
        validations: ["required"],
        label: "Full Name",
      },
      {
        name: "subject",
        validations: ["required"],
        label: "Subject",
      },
      {
        name: "email",
        validations: ["required", "email"],
        label: "Email",
      },
      {
        name: "phone",
        validations: ["required", "phoneNumber"],
        label: "Phone",
      },
      {
        name: "message",
        validations: ["required"],
        label: "Message",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = {
        fullName: formData.fullName,
        subject: formData.subject,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      };
      try {
        toast("Submitted Successfully", "successToast");
        setFormData({
          fullName: "",
          subject: "",
          email: "",
          phone: "",
          message: "",
        });
        // axios
        //   .post("/api/career", bodyData, {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   })
        //   .then((res) => {
        //     toast("Career Added Successfully", "successToast");
        //     setFormData({
        //       requirements: "",
        //       expertise: "",
        //       image: [],
        //       jobRole: "",
        //     });
        //     setClearDragAndDrop(!clearDragAndDrop);
        //     dispatch(stopLoading());
        //   })
        //   .catch((err) => {
        //     toast("Error in Adding Career", "errorToast");
        //     dispatch(stopLoading());
        //   });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
        // dispatch(stopLoading());
      }
    }
  };
  return (
    <section id="home-contact-us">
      <div className="contactus-main-container">
        <div className="contactus-left-container">
          <img
            src={ContactUsImage}
            alt="contact-us-pic"
            className="contact-us-image"
          ></img>
        </div>
        <div className="contactus-right-container">
          <SimpleLayoutHeading
            line1={"Contact Us"}
            line2={"Get in Touch"}
          ></SimpleLayoutHeading>
          {/* <div className="contactus-heading">Contact Us</div> */}
          <div className="contact-us-desc">
            We value your feedback and inquiries, and we strive to provide
            prompt and helpful assistance to all our students.{" "}
          </div>
          <div className="contact-us-form">
            <CustomText
              label={"Full Name"}
              labelClassName={"contact-us-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "fullName");
              }}
              error={errors?.fullName}
              value={formData.fullName}
              placeholder={"Enter your full name"}
            ></CustomText>
            <CustomText
              label={"Subject"}
              labelClassName={"contact-us-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "subject");
              }}
              error={errors?.subject}
              value={formData.subject}
              placeholder={"Enter your subject"}
            ></CustomText>
            <div className="contactus-form-field-group contact-us-form">
              <CustomText
                label={"Email Address"}
                labelClassName={"contact-us-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "email");
                }}
                error={errors?.email}
                value={formData.email}
                placeholder={"Enter your email"}
              ></CustomText>
              <CustomText
                label={"Phone"}
                labelClassName={"contact-us-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "phone");
                }}
                error={errors?.phone}
                value={formData.phone}
                placeholder={"Enter your phone"}
              ></CustomText>
            </div>
            <CustomTextArea
              label={"Message"}
              labelClassName={"contact-us-label"}
              rows={10}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "message");
              }}
              error={errors?.message}
              value={formData.message}
              placeholder={"Type your message here..."}
            ></CustomTextArea>
            <button
              type="button"
              className="contact-us-submit-button"
              onClick={handleSubmit}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;
