import { useState } from "react";
import CardTable from "../../../../components/CardTable/CardTable";
import "./AdminAllStudents.css";
import { headerData, caption } from "./TableConfig";
import { AdminAllStudentsData } from "./AdminAllStudentsData";

const AdminAllStudents = () => {
  const onDelete = () => {
    alert("Deleted");
  };
  const [mode, setMode] = useState("add");
  const tableData = Array.isArray(AdminAllStudentsData)
    ? AdminAllStudentsData.map((data, index) => {
        return {
          tableViewInfo: [
            data.studentId,
            data.name,
            data.mobile,
            data.email,
            data.school,
          ],
          onClickTr: `/dashboard/admin/student/:studentID`,
          id: index,
        };
      })
    : [];
  return (
    <div className="admin-all-chapters-container">
      <div className="admin-all-chapters-header">
        <div className="admin-main-page-title">Students</div>
      </div>
      <div style={{ overflow: "auto" }}>
        <CardTable
          caption={caption}
          headerData={headerData}
          tableData={tableData}
          disableRowClick={false}
        />
      </div>
    </div>
  );
};

export default AdminAllStudents;
