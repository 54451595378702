import CustomText from "../../../components/FormComponents/Text/CustomText";
import Navigation from "../../../components/Navigation/Navigation";
import useHandleFormData, {
  performValidation,
} from "../../../hooks/useHandleFormData";
import "./UserProfile.css";
const UserProfile = () => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();

  const handleSubmitUserData = () => {
    const validationConfig = [
      {
        name: "firstName",
        validations: ["required"],
        label: "",
      },
      {
        name: "mobileNumber",
        validations: ["required", "phoneNumber"],
        label: "",
      },
      {
        name: "lastName",
        validations: ["required"],
        label: "",
      },
      {
        name: "school",
        validations: ["required"],
        label: "",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      try {
        toast("Updated Successfully", "successToast");
        setFormData({
          firstName: "",
          mobileNumber: "",
          lastName: "",
          school: "",
        });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
      }
    }
  };

  const handleSubmitPassword = () => {
    const validationConfig = [
      {
        name: "oldPassword",
        validations: ["required"],
        label: "",
      },
      {
        name: "newPassword",
        validations: ["required"],
        label: "",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      try {
        toast("Updated Successfully", "successToast");
        setFormData({
          oldPassword: "",
          newPassword: "",
        });
      } catch (err) {
        toast("Oops! Something went wrong", "errorToast");
      }
    }
  };
  return (
    <section id="user-profile-user-layout">
      <Navigation
        config={[
          {
            name: "Home",
            link: "/dashboard/user",
          },
          {
            name: "Profile",
          },
        ]}
      />
      <div className="user-profile-user-layout">
        <div className="user-profile-container">
          <div className="user-profile-form-1">
            <div className="heading">General Information</div>
            <div className="user-profile-form-field-container">
              <CustomText
                label={"First Name"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "firstName");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.firstName}
                value={formData.firstName}
                placeholder={"First Name"}
              ></CustomText>
              <CustomText
                label={"Mobile Number"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "mobileNumber");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.mobileNumber}
                value={formData.mobileNumber}
                placeholder={"Mobile Number"}
              ></CustomText>
            </div>
            <div className="user-profile-form-field-container">
              <CustomText
                label={"Last Name"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "lastName");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.lastName}
                value={formData.lastName}
                placeholder={"Last Name"}
              ></CustomText>
              <CustomText
                label={"School"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "school");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.school}
                value={formData.school}
                placeholder={"School"}
              ></CustomText>
            </div>
            <button
              type="button"
              className="user-profile-update-button"
              onClick={handleSubmitUserData}
            >
              Update
            </button>
          </div>
          <div className="user-profile-form-2">
            <div className="heading" style={{ marginTop: "65px" }}>
              Change Password
            </div>
            <div className="user-profile-form-field-container">
              <CustomText
                label={"Old Password"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "oldPassword");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.oldPassword}
                value={formData.oldPassword}
                placeholder={"Old Password"}
                type={"password"}
              ></CustomText>
              <CustomText
                label={"New Password"}
                labelClassName={"user-profile-label"}
                flex={"1"}
                onChange={(e) => {
                  handleChange(e, "newPassword");
                }}
                fieldClassName={"bank-payment-field"}
                error={errors?.newPassword}
                value={formData.newPassword}
                placeholder={"New Password"}
                type={"password"}
              ></CustomText>
            </div>
            <button
              type="button"
              className="user-profile-update-button"
              onClick={handleSubmitPassword}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
