import "./Tutor.css";
import TutorImage from "../../../../assets/simpleLayout/tutor/tutor.png";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import SimpleLayoutHeading from "../../../../components/SimpleLayoutHeading.css/SimpleLayoutHeading";
const Tutor = () => {
  return (
    <section id="home-meet-tutor">
      <SimpleLayoutHeading
        line1={"Tutor"}
        line2={"Meet the Hero"}
      ></SimpleLayoutHeading>
      <h4 className="tutor-heading-desc">
        Inspiring Curiosity and Understanding in the Natural Sciences.
        <br></br>
        We offer the knowledge and abilities.
      </h4>
      <div className="tutor-main-container">
        <div className="tutor-left-container">
          <img src={TutorImage} alt="Tutor" className="tutor-image" />
          <div className="tutor-name heading-color-black">Marvin McKinney</div>
          <div className="tutor-position heading-color-blue">
            Co-op & Internships Program & Operations Manager
          </div>
          <div className="tutor-about">
            Former frontend dev for Linear, Coinbase, and Postscript.
          </div>
          <div className="tutor-social-media-container">
            <FaTwitter style={{ color: "#adacac" }} />
            <FaLinkedin style={{ color: "#adacac" }} />
          </div>
        </div>
        <p className="tutor-right-container">
          A dedicated and passionate biology teacher with a strong commitment to
          fostering a love for the natural sciences. Skilled in creating
          engaging and interactive lessons that make complex biological concepts
          accessible to students. Experienced in utilizing a variety of teaching
          methods, including hands-on experiments and digital resources, to
          enhance learning and stimulate curiosity. Committed to supporting
          students' academic growth and encouraging scientific inquiry both
          inside and outside the classroom.
        </p>
      </div>
    </section>
  );
};
export default Tutor;
