import "./CircleDesign.css";
import CircleDesignSvg from "./CircleDesign.svg";

const CircleDesign = ({ className }) => {
  return (
    <span className="circle-design-container">
      <img
        src={CircleDesignSvg}
        className={className}
        draggable={false}
        alt="circle-design"
      ></img>
    </span>
  );
};

export default CircleDesign;
