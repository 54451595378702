import CustomFile from "../../../../../../components/FormComponents/CustomImageFile/CustomFile";
import CustomText from "../../../../../../components/FormComponents/Text/CustomText";
import useHandleFormData from "../../../../../../hooks/useHandleFormData";

const AddNewExam = ({ title, buttontext }) => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  return (
    <div className="add-new-chapter-container">
      <div className="header">{title}</div>
      <div className="body">
        <div className="add-new-unit-body-container">
          <div>
            <CustomText
              label={"Name"}
              labelClassName={"bank-payment-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "name");
              }}
              fieldClassName={"add-new-chapter-field "}
              error={errors?.name}
              value={formData.name}
              placeholder={"Enter Chapter Name"}
            ></CustomText>
            <CustomText
              label={"Google Form Link"}
              labelClassName={"bank-payment-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "name");
              }}
              fieldClassName={"add-new-chapter-field "}
              error={errors?.name}
              value={formData.name}
              placeholder={"Enter Chapter Name"}
            ></CustomText>
          </div>
          <div>
            <CustomText
              label={"Duration"}
              labelClassName={"bank-payment-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "name");
              }}
              fieldClassName={"add-new-chapter-field "}
              error={errors?.name}
              value={formData.name}
              placeholder={"Enter Chapter Name"}
            ></CustomText>
            <CustomFile
              placeholder={"Upload PDF"}
              label={"Exam Paper (PDF)"}
              labelClassName={"bank-payment-label"}
              flex={"100%"}
              onChange={(e) => {
                handleChange(e, "pdf");
              }}
              fieldClassName={"add-new-chapter-field "}
              error={errors?.pdf}
              value={formData.pdf}
              fileName={formData?.pdf?.name}
            ></CustomFile>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="add-chapter-button">{buttontext}</button>
        </div>
      </div>
    </div>
  );
};

export default AddNewExam;
