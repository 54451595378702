import { Link } from "react-router-dom";
import UserLayoutSearchHeader from "../../../../components/UserLayout/UserLayoutSearchHeader/UserLayoutSearchHeader";
import "./ChapterContentExams.css";
import CardTable from "../../../../components/CardTable/CardTable";
import { headerData, caption } from "./TableConfig";
import { SampleExamData } from "./SampleData";
import { MinutesToHour } from "../../../../functions/TimeFunctions";
import DemoPagination from "../../../../components/DemoPagination/DemoPagination";
const ChapterContentExams = ({ chapterId }) => {
  const tableData = Array.isArray(SampleExamData)
    ? SampleExamData.map((data, index) => {
        return {
          tableViewInfo: [
            data.examId,
            data.examName,
            MinutesToHour(data.examDuration),
            <Link
              to={`exams/${"examID"}`}
              className="exam-chapter-start-button"
            >
              Start
            </Link>,
          ],
          onClickTr: ``,
          id: index,
        };
      })
    : [];
  return (
    <>
      <div
        className="chapter-content-exam-container"
        style={{ position: "relative", marginTop: "20px" }}
      >
        <CardTable
          caption={caption}
          headerData={headerData}
          tableData={tableData}
          disableRowClick={true}
        />
      </div>
      <DemoPagination />
    </>
  );
};

export default ChapterContentExams;
