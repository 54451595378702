import React, { useState } from "react";
import "./PopUp.css";
import close from "./close.png";
import warning from "./warning.png";
const PopUp = ({
  message,
  onYes,
  type,
  buttonText,
  className,
  icon,
  hideYes,
  noButtonText,
  heading,
}) => {
  const [viewModal, setViewModal] = useState(false);
  const handleShowModel = (event) => {
    event.stopPropagation();
    setViewModal(true);
  };

  const closeModal = (event) => {
    event.stopPropagation();
    setViewModal(false);
  };

  const agreeModal = (event) => {
    event.stopPropagation();
    setViewModal(false);
    if (onYes[1] !== undefined) {
      onYes[0](...onYes[1]);
    } else {
      onYes[0]();
    }
  };

  return (
    <React.Fragment>
      <button
        title="Delete"
        type="button"
        onClick={handleShowModel}
        className={icon ? "popup-icon" : className}
      >
        {buttonText}
      </button>
      <div
        aria-hidden={false}
        className={
          viewModal
            ? "popup-container popup-container-active"
            : "popup-container"
        }
        onClick={(event) => {
          setViewModal(false);
        }}
      >
        <div
          className="popup-main"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            src={type === "warning" ? warning : close}
            alt="questionmark"
            className="popup-question-mark"
          ></img>
          <div className="are-you-sure-popup">
            {heading ? heading : "Are you sure ?"}
          </div>
          <div className="popup-body">{message}</div>
          {/* <div className="popup-divider"></div> */}
          <div className="popup-footer">
            {hideYes ? null : (
              <button
                onClick={agreeModal}
                className="popup-button yes-popup-button"
                type="button"
              >
                Yes
              </button>
            )}
            <button
              onClick={closeModal}
              className="popup-button no-popup-button"
              type="button"
              style={{background:type==="warning"?"#bfa811c9":""}}
            >
              {noButtonText ? noButtonText : "No"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopUp;
