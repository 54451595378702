import { Outlet } from "react-router-dom";
import "./UserLayout.css";
import Header from "./header/Header";
import TopVectorImage from "./TopVector.svg";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
export default function UserLayout() {
  return (
    <>
      <main className="user-layout-main-container">
        <img
          className="top-rectangle-ul"
          src={TopVectorImage}
          alt="top-vector"
        />
        <Header></Header>
        <Outlet />
        <ScrollToTop />
      </main>
    </>
  );
}
