import { useEffect, useState } from "react";
import "./Navigation.css";
import { Link } from "react-router-dom";

const Navigation = ({ config }) => {
  //   const config = [
  //     {
  //       name: "Chapter 1",
  //       link: "/dashboard/user",
  //     },
  //     {
  //       name: "Units",
  //     },
  //   ];
  const [naviSize, setNaviSize] = useState(0);
  useEffect(() => {
    setNaviSize(config.length);
  }, []);

  return (
    <div className="screen-view-navigation">
      {config.map((item, index) => {
        let naviComponent;
        if (item?.link) {
          naviComponent = (
            <Link
              to={item?.link}
              style={{
                textDecoration: "none",
                color: index === naviSize - 1 ? "#bababa" : "inherit",
              }}
            >
              {item?.name}
            </Link>
          );
        } else {
          naviComponent = (
            <span
              style={{
                color: index === naviSize - 1 ? "#bababa" : "inherit",
              }}
            >
              {item?.name}
            </span>
          );
        }

        return (
          <span key={index}>
            {naviComponent}
            {index !== naviSize - 1 && " / "}
          </span>
        );
      })}
    </div>
  );
};

export default Navigation;
