import "./AdminStudentChapters.css";

const AdminStudentChapters = () => {
  return (
    <div>
      <div>AdminStudentChapters</div>
    </div>
  );
};

export default AdminStudentChapters;
