import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserLayoutSearchHeader from "../../../components/UserLayout/UserLayoutSearchHeader/UserLayoutSearchHeader";
import EnrolledChapterUnits from "./ChapterContentUnits/ChapterContentUnits";
import EnrolledChapterExams from "./ChapterContentExams/ChapterContentExams";
import "./ChapterContent.css";
import CustomPopup from "../../../components/Dashboard/CustomPopup/CustomPopup";
import BankPaymentPopup from "./SubComponents/BankPaymentPopup";
import ChapterEnrollScreen from "./SubComponents/ChapterEnrollScreen";
import Navigation from "../../../components/Navigation/Navigation";
import { AllChaptersData } from "../AllChapters/AllChaptersData";

/**
 * This is the main component for displaying the content of a chapter.
 * It handles the enrolling, navigation, and display of units and exams.
 */

const ChapterContent = () => {
  // selected chapter : for demo
  const [selectedChapter, setSelectedChapter] = useState({});
  // State variables
  const [isEnrolled, setIsEnrolled] = useState(true);
  // Currently Selected Tab: Can take values Units or Exams
  const [selected, setSelected] = useState("Units");
  // View Enroll Screen: If true, the enroll screen will be displayed in the side
  const [viewEnrollScreen, setViewEnrollScreen] = useState(false);
  // to open bank payment popup
  const [openPopUp, setOpenPopUp] = useState(false);
  // to check if enroll button is clicked; Mainly for showing button animation
  const [isClicked, setIsClicked] = useState(false);
  // if showExamAndUnits true, exam and unit tab will be shown. in mobile screen (<550px) we hide exams and units when enroll button is clicked
  const [showExamAndUnits, setShowExamAndUnits] = useState(true);
  const [bankPaymentUploadStatus, setBankPaymentUploadStatus] = useState(false);

  useEffect(() => {
    // if showExamAndUnits true, exam and unit tab will be shown. in mobile screen (<550px) we hide exams and units when enroll button is clicked
    const handleResize = () => {
      if (window.innerWidth < 550) {
        if (viewEnrollScreen) {
          setShowExamAndUnits(false);
        } else {
          setShowExamAndUnits(true);
        }
      } else {
        setShowExamAndUnits(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewEnrollScreen]);

  // Enroll button for units
  const enrollButton = (
    <button
      className={isClicked ? "btn is-clicked btn-normal-position" : "btn"}
      onClick={() => {
        setViewEnrollScreen(!viewEnrollScreen);
        setIsClicked(!isClicked);
        setBankPaymentUploadStatus(false);
      }}
    >
      {isClicked ? "Close" : "Enroll"}
    </button>
  );

  // Get the chapterID from the URL parameters
  const { chapterID, openTab } = useParams();
  useEffect(() => {
    if (openTab === "exams") {
      setSelected("Exams");
    } else {
      setSelected("Units");
    }
  }, []);

  // Check if the user is enrolled in the chapter based on the chapterID
  useEffect(() => {
    const currentChapter = AllChaptersData.find((item) => {
      return item.id === chapterID;
    });
    // Sample Object for current chapter
    //   {
    //     "id": "100",
    //     "chapterEnglish": "Chemical & Cellular Basic of life",
    //     "chapterTamil": "உயிரின் இரசாயண கால அடிப்படை",
    //     "chapterNumber": "1",
    //     "units": "10",
    //     "exams": "5",
    //     "image": "https://ugc.futurelearn.com/uploads/images/2d/d7/2dd799f7-c8a7-475f-ab78-755cd8bf7a4d.png",
    //     "isEnrolled": true
    // }

    setSelectedChapter(currentChapter);
    setIsEnrolled(currentChapter.isEnrolled);
  }, []);

  return (
    <section id="enrolled-chapter-units">
      <Navigation
        config={[
          {
            name: isEnrolled ? "Enrolled Chapters" : "All Chapters",
            link: isEnrolled
              ? "/dashboard/user/chapters/enrolled"
              : "/dashboard/user/chapters",
          },
          {
            name: { selectedChapter }
              ? selectedChapter.chapterEnglish
              : "Chapter",
          },
          {
            name: selected === "Units" ? "Units" : "Exams",
          },
        ]}
      />
      <UserLayoutSearchHeader
        hideRightSide={selected === "Exams" ? true : false}
        leftSide={
          showExamAndUnits && (
            <span className="units-exams-button-container">
              <span className="units-exam-button-set">
                <Link
                  className={
                    selected === "Units"
                      ? "ec-button ec-button-active"
                      : "ec-button"
                  }
                  onClick={() => {
                    setSelected("Units");
                  }}
                >
                  Units
                </Link>
                <Link
                  className={
                    selected === "Exams"
                      ? "ec-button ec-button-active"
                      : "ec-button"
                  }
                  onClick={() => {
                    setSelected(isEnrolled ? "Exams" : "Units");
                  }}
                  style={isEnrolled ? {} : { cursor: "not-allowed" }}
                >
                  Exams
                </Link>
              </span>
            </span>
          )
        }
        rightSide={"Search for a Units"}
      />
      {/* Enroll button is only visible when chapter is not enrolled */}
      {/* Showing exams and units view and opens the ChapterEnrollScreen when click enroll button when chapter is not enrolled */}
      <div
        className={`chapter-content-main-container ${
          viewEnrollScreen ? "split-width" : "full-width"
        }`}
      >
        {
          // if showExamAndUnits true, exam and unit tab will be shown. in mobile screen (<550px) we hide exams and units when enroll button is clicked
          showExamAndUnits && (
            <div>
              {selected === "Units" ? (
                <EnrolledChapterUnits
                  // If the user is not enrolled, pass the enroll button, else pass null
                  enrollButton={
                    isEnrolled ? null : isClicked ? enrollButton : enrollButton
                  }
                />
              ) : (
                <EnrolledChapterExams />
              )}
            </div>
          )
        }

        {viewEnrollScreen ? (
          <ChapterEnrollScreen
            // passed to open the bank payment popup when user select bank payment radio input from enroll screen
            data={selectedChapter}
            setOpenPopUp={setOpenPopUp}
            onClose={() => {
              setViewEnrollScreen(false);
              setIsClicked(false);
            }}
            bankPaymentUploadStatus={bankPaymentUploadStatus}
          />
        ) : null}
      </div>

      {/* When user click the bank payment radio button, this popup will open */}
      <CustomPopup
        closeButtonStyle={{ color: "white" }}
        isOpen={openPopUp}
        onClose={() => {
          setOpenPopUp(false);
        }}
        style={{
          overflow: "auto",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <BankPaymentPopup
          setBankPaymentUploadStatus={setBankPaymentUploadStatus}
          close={() => {
            setOpenPopUp(false);
          }}
        />
      </CustomPopup>
    </section>
  );
};

export default ChapterContent;
