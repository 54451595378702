import { useEffect, useState } from "react";
import ChapterCard from "../../../../components/ChapterCard/ChapterCard";
import SimpleLayoutHeading from "../../../../components/SimpleLayoutHeading.css/SimpleLayoutHeading";
import { ChaptersData } from "./ChapterData";
import "./Services.css";

const Services = () => {
  const [visibleCard, setVisibleCards] = useState(3);
  const [columns, setColumns] = useState(3);
  const [controllerCount, setControllerCount] = useState(3);
  const [activeController, setActiveController] = useState(1);

  useEffect(() => {
    let numberOfCards = ChaptersData.length;

    const handleResize = () => {
      if (window.innerWidth < 778) {
        // 600px
        setVisibleCards(3);
        setColumns(1);
        setControllerCount(Math.ceil(numberOfCards / 3));
      } else if (window.innerWidth < 1179) {
        // 1178px
        setVisibleCards(2);
        setColumns(2);
        setControllerCount(Math.ceil(numberOfCards / 2));
      } else {
        setVisibleCards(3);
        setColumns(3);
        setControllerCount(Math.ceil(numberOfCards / 3));
      }
      if (numberOfCards < 3) {
        setVisibleCards(numberOfCards);
        setColumns(numberOfCards);
        setControllerCount(numberOfCards);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveController((prevCount) => (prevCount % controllerCount) + 1);
    }, 3000);

    // Cleanup the interval on component unmount or when controllerCount changes
    return () => clearInterval(interval);
  }, [controllerCount]);

  function sliceArrayByPage(array, pagenumber, availablepages, visibleCount) {
    const itemsPerPage = Math.max(
      visibleCount,
      Math.ceil(array.length / availablepages)
    );
    const startIndex = (pagenumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return array.slice(startIndex, endIndex);
  }

  // console.log("Visible Card: " + visibleCard);
  // console.log("Controller Count: " + controllerCount);

  return (
    <section id="home-our-services">
      <SimpleLayoutHeading
        line1={"Our Courses"}
        line2={
          <span className="heading-color-black">
            Fostering a playful & engaging learning <br></br>environment
          </span>
        }
      ></SimpleLayoutHeading>
      <div
        className="chapter-display"
        style={{
          gridTemplateColumns: Array(columns).fill("1fr").join(" "),
        }}
      >
        {sliceArrayByPage(
          ChaptersData,
          activeController,
          ChaptersData.length,
          visibleCard
        ).map((chapter, index) => {
          return (
            <ChapterCard
              nameEnglish={chapter.chapterEnglish}
              nameTamil={chapter.chapterTamil}
              chapterHeader={"Chapter " + chapter.chapterNumber}
              units={chapter.units}
              exams={chapter.exams}
              key={index}
              image={chapter.image}
              id={chapter.id}
            ></ChapterCard>
          );
        })}
      </div>
      <div className="controller-container">
        {Array.from({ length: controllerCount }).map((_, index) => (
          <div
            key={index}
            onClick={() => setActiveController(index + 1)}
            className={
              activeController === index + 1
                ? "service-chap-controller service-chap-controller-active"
                : "service-chap-controller"
            }
          ></div>
        ))}
      </div>
    </section>
  );
};

export default Services;
