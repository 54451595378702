export const AllChaptersData = [
  {
    id:"100",
    chapterEnglish: "Chemical & Cellular Basic of life",
    chapterTamil: "உயிரின் இரசாயண கால அடிப்படை",
    chapterNumber: "1",
    units: "10",
    exams: "5",
    image:
      "https://ugc.futurelearn.com/uploads/images/2d/d7/2dd799f7-c8a7-475f-ab78-755cd8bf7a4d.png",
    isEnrolled: true,
  },
  {
    id:"101",
    chapterEnglish: "Genetics and Evolution",
    chapterTamil: "மரபியல் மற்றும் பரிணாமம்",
    chapterNumber: "2",
    units: "8",
    exams: "4",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHQXX1HKgI5ZgIGJDoPt4HN6isM34ootODtA&s",
    isEnrolled: false,
  },
  {
    id:"102",
    chapterEnglish: "Ecology and Environment",
    chapterTamil: "பசுமை மற்றும் சுற்றுச்சூழல்",
    chapterNumber: "3",
    units: "7",
    exams: "3",
    image:
      "https://blogassets.leverageedu.com/blog/wp-content/uploads/2020/01/17185544/Ecology-and-Environment.jpg",
    isEnrolled: true,
  },
  {
    id:"103",
    chapterEnglish: "Plant Physiology",
    chapterTamil: "தாவர உடல் இயக்கம்",
    chapterNumber: "4",
    units: "9",
    exams: "5",
    image:
      "https://d2csxpduxe849s.cloudfront.net/media/E32629C6-9347-4F84-81FEAEF7BFA342B3/1DC7ADD6-5410-4BAC-B87B6652BEBCBF14/WebsiteJpg_XL-shutterstock_1503818402%20-2.jpg",
    isEnrolled: false,
  },
  {
    id:"104",
    chapterEnglish: "Animal Physiology",
    chapterTamil: "விலங்கு உடல் இயக்கம்",
    chapterNumber: "5",
    units: "10",
    exams: "6",
    image:
      "https://csb.utoronto.ca/wp-content/uploads/2020/04/Animal-Physiology.png",
    isEnrolled: true,
  },
  {
    id:"105",
    chapterEnglish: "Molecular Biology",
    chapterTamil: "மூலக்கூறு உயிரியல்",
    chapterNumber: "6",
    units: "6",
    exams: "3",
    image:
      "https://ocw.mit.edu/courses/7-01sc-fundamentals-of-biology-fall-2011/1dc8c931f4241fa853ff77dee621eb18_Molec_Bio_Unit_image.jpg",
    isEnrolled: false,
  },
  {
    id:"106",
    chapterEnglish: "Biochemistry",
    chapterTamil: "உயிர்வேதியியல்",
    chapterNumber: "7",
    units: "8",
    exams: "4",
    image: "https://learnt.io/blog/content/images/2022/12/302.jpg",
    isEnrolled: true,
  },
  {
    id:"107",
    chapterEnglish: "Microbiology",
    chapterTamil: "நுண்ணுயிரியல்",
    chapterNumber: "8",
    units: "5",
    exams: "2",
    image:
      "https://d2csxpduxe849s.cloudfront.net/media/E32629C6-9347-4F84-81FEAEF7BFA342B3/D7C8E8F5-EB21-4B26-BE7D25D7B2089254/B9458B20-0699-4EE0-A50A3EE8F902C258/WebsiteJpg_XL-FMICB_Main%20Visual_Cyan_Website.jpg",
    isEnrolled: false,
  },
  {
    id:"108",
    chapterEnglish: "Immunology",
    chapterTamil: "நோய் எதிர்ப்பு அறிவியல்",
    chapterNumber: "9",
    units: "7",
    exams: "3",
    image:
      "https://www.imperial.ac.uk/media/migration/research-groups/Immunology--tojpeg_1510824301678_x2.jpg",
    isEnrolled: true,
  },
  {
    id:"109",
    chapterEnglish: "Neuroscience",
    chapterTamil: "நரம்பியல்",
    chapterNumber: "10",
    units: "6",
    exams: "3",
    image:
      "https://neurosciencenews.com/files/2023/05/neuroscience-news-week.jpg",
    isEnrolled: false,
  },
];
