import "./SimpleLayoutHeading.css";
const SimpleLayoutHeading = ({ line1, line2 }) => {
  return (
    <>
      <h2 className="sl-sub-heading heading-color-blue">{line1}</h2>
      <h3 className="sl-main-heading heading-color-black">{line2}</h3>
    </>
  );
};
export default SimpleLayoutHeading;
