export const Data = [
  {
    examId: "E001",
    name: "Cell Structure Overview Exam",
    url: "https://forms.gle/abc123",
    duration: "1hr 20min",
  },
  {
    examId: "E002",
    name: "DNA Replication Detailed Exam",
    url: "https://forms.gle/def456",
    duration: "1hr 20min",
  },
  {
    examId: "E003",
    name: "Photosynthesis Process Analysis Exam",
    url: "https://forms.gle/ghi789",
    duration: "1hr 20min",
  },
  {
    examId: "E004",
    name: "Human Nervous System Exam",
    url: "https://forms.gle/jkl012",
    duration: "1hr 20min",
  },
  {
    examId: "E005",
    name: "Mendelian Genetics Principles Exam",
    url: "https://forms.gle/mno345",
    duration: "1hr 20min",
  },
  {
    examId: "E006",
    name: "Evolution Natural Selection Exam",
    url: "https://forms.gle/pqr678",
    duration: "1hr 20min",
  },
  {
    examId: "E007",
    name: "Ecosystem Dynamics Analysis Exam",
    url: "https://forms.gle/stu901",
    duration: "1hr 20min",
  },
  {
    examId: "E008",
    name: "Plant Reproduction Growth Exam",
    url: "https://forms.gle/vwx234",
    duration: "1hr 20min",
  },
  {
    examId: "E009",
    name: "Biotechnology Techniques Overview Exam",
    url: "https://forms.gle/yz1234",
    duration: "1hr 20min",
  },
  {
    examId: "E010",
    name: "Human Immune System Exam",
    url: "https://forms.gle/abc567",
    duration: "1hr 20min",
  },
];


export const Headers = ["Exam Id", "Name ", "Url", "Duration", "Action"];
export const CellWidths = [
  { minWidth: "50px", maxWidth: "auto", width: "5vw" },
  { minWidth: "100px", maxWidth: "auto", width: "21vw" },
  { minWidth: "auto", maxWidth: "auto", width: "auto" },
  { minWidth: "auto", maxWidth: "auto", width: "5vw" },
  { minWidth: "auto", maxWidth: "auto", width: "5vw" },
];
export const RowAlignments = ["left", "left", "center", "center", "right"];
