export const Data = [
  {
    unitId: "U001",
    name: "Cell Structure and Function",
    url: "https://www.youtube.com/watch?v=1Z9pqST72is",
    duration: "1hr 20min",
  },
  {
    unitId: "U002",
    name: "DNA Replication and Repair",
    url: "https://www.youtube.com/watch?v=2jFvn28i9UQ",
    duration: "1hr 20min",
  },
  {
    unitId: "U003",
    name: "Photosynthesis Process Explained",
    url: "https://www.youtube.com/watch?v=3K2V_KyL0Jo",
    duration: "1hr 20min",
  },
  {
    unitId: "U004",
    name: "Human Nervous System",
    url: "https://www.youtube.com/watch?v=4zFJSt1rmJw",
    duration: "1hr 20min",
  },
  {
    unitId: "U005",
    name: "Principles of Mendelian Genetics",
    url: "https://www.youtube.com/watch?v=5l8GtEV8TnY",
    duration: "1hr 20min",
  },
  {
    unitId: "U006",
    name: "Evolution and Natural Selection",
    url: "https://www.youtube.com/watch?v=6g7i7KVH9Nc",
    duration: "1hr 20min",
  },
  {
    unitId: "U007",
    name: "Ecosystem Dynamics",
    url: "https://www.youtube.com/watch?v=7hzKmRBLHZ4",
    duration: "1hr 20min",
  },
  {
    unitId: "U008",
    name: "Plant Reproduction and Growth",
    url: "https://www.youtube.com/watch?v=8hKMLL8VR1g",
    duration: "1hr 20min",
  },
  {
    unitId: "U009",
    name: "Biotechnology Techniques",
    url: "https://www.youtube.com/watch?v=9bLML8s6Z1s",
    duration: "1hr 20min",
  },
  {
    unitId: "U010",
    name: "Human Immune System",
    url: "https://www.youtube.com/watch?v=10pTLZ9X2j4",
    duration: "1hr 20min",
  },
];

export const Headers = ["Unit Id", "Name ", "Url", "Duration", "Action"];
export const CellWidths = [
  { minWidth: "50px", maxWidth: "auto", width: "5vw" },
  { minWidth: "100px", maxWidth: "auto", width: "21vw" },
  { minWidth: "auto", maxWidth: "auto", width: "auto" },
  { minWidth: "auto", maxWidth: "auto", width: "5vw" },
  { minWidth: "auto", maxWidth: "auto", width: "5vw" },
];
export const RowAlignments = ["left", "left", "center", "center", "right"];
