import "./UserLayoutSearchHeader.css";
import { IoIosSearch } from "react-icons/io";
const UserLayoutSearchHeader = ({ leftSide, hideRightSide, rightSide }) => {
  return (
    <div className="ulsh-container">
      {leftSide && leftSide}
      {hideRightSide === true ? null : (
        <div className="ulsh-search-container">
          <input
            type="text"
            placeholder={rightSide ? rightSide : "Search for a Chapter"}
            className="ulsh-input"
          ></input>
          <div className="ulsh-search-input">
            <IoIosSearch />
          </div>
          <button type="button" className="ulsh-search-button">
            <IoIosSearch />
          </button>
        </div>
      )}
    </div>
  );
};

export default UserLayoutSearchHeader;
