import { useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../../assets/logo.png";
import StudentAvatar from "./studentAvatar.svg";
const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="ul-header-container">
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
          onClick={() => {
            navigate("/dashboard/user");
          }}
        >
          <img
            src={Logo}
            alt={"Bioworld logo"}
            className="ul-header-logo not-selectable"
            draggable={false}
          />{" "}
          <h3 class="logo-text" style={{ color: "var(--primary-color)" }}>
            BIO WORLD
          </h3>
        </div>

        <img
          src={
            "https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
          }
          alt="user-logo not-selectable"
          draggable={false}
          className="ul-header-user"
          onClick={() => navigate("/dashboard/user/profile")}
        />
      </div>
      <section id="user-layout-student-info-header">
        <div className="ul-header-greeting-container">
          <div className="ul-header-greeting">
            <div className="ul-header-greeting-student-name-container">
              <div className="ul-header-greeting-student-welcome">Welcome,</div>
              <div className="ul-header-greeting-student-name">Mr Student</div>
            </div>
            <div className="ul-header-greeting-student-message">
              Have a nice day
            </div>
          </div>
          <img
            src={StudentAvatar}
            draggable={false}
            alt="student-avatar"
            className="ul-header-student-avatar not-selectable"
          />
        </div>
      </section>
    </>
  );
};

export default Header;
