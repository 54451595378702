const headerData = [
  {
    name: "Exam ID",
    minWidth: "50px",
    maxWidth: "auto",
    width: "100px",
    textAlign: "center",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Exam Name",
    minWidth: "50px",
    maxWidth: "auto",
    width: "200px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Duration",
    minWidth: "40px",
    maxWidth: "auto",
    width: "40px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Action",
    minWidth: "40px",
    maxWidth: "auto",
    width: "60px",
    textAlign: "center",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
];

const caption = "";

export { headerData, caption };
