import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { GrStatusGood } from "react-icons/gr";
import { IoWarning } from "react-icons/io5";
const ChapterEnrollScreen = ({
  setOpenPopUp,
  onClose,
  data,
  bankPaymentUploadStatus,
}) => {
  let paymentPerHour = 100;
  const [extrahours, setExtraHours] = useState(0);
  const [selectedOption, setSelectedOption] = useState("online-payment");
  const [price, setPrice] = useState(2000);
  useEffect(() => {
    setPrice(2000 + extrahours * paymentPerHour);
  }, [extrahours]);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="enroll-screen-main-container">
      <div className="container-1">
        <div className="header">
          <div className="heading">
            <IoClose
              className="chapter-enroll-screen-close-button"
              onClick={onClose}
            />
            Order Details
          </div>
          <div className="chapter-name">Chapter 2</div>
        </div>
        <div>
          <img src={data?.image} className="image"></img>
          <div className="headings">
            <div className="heading-tamil">{data?.chapterTamil}</div>
            <div className="heading-english">{data?.chapterEnglish}</div>
            <div className="course-desc">
              {data?.units} Units{" "}
              <span className="chapter-content-divider-dot">
                <BsDot />
              </span>{" "}
              {data?.exams} Exams{" "}
              <span className="chapter-content-divider-dot">
                <BsDot />
              </span>{" "}
              10h 7m Total Length
            </div>
          </div>
        </div>
      </div>
      <div className="container-2">
        <div>
          <div className="price-data">
            <div className="price-heading">Price</div>
            <div className="price-amount">Rs.2000</div>
          </div>
          <div className="line-divider"></div>
          <div className="price-data">
            <div className="price-heading">Duration</div>
            <div className="price-amount">50 hrs</div>
          </div>
          <div className="price-data extra-hours">
            <div className="price-heading">Extra Hours</div>
            <div className="price-amount">
              <input
                type="number"
                className="number-counter"
                value={extrahours}
                onChange={(e) => {
                  setExtraHours(e?.target?.value || 0);
                }}
                min={0}
                max={100}
              ></input>
              hrs
            </div>
          </div>
          <div className="line-divider"></div>
          <div className="price-data">
            <div className="price-heading">Validity</div>
            <div className="price-amount">30 Days</div>
          </div>
          <div className="line-divider"></div>
        </div>
        <div className="payment-methods">
          <div className="payment-main-container">
            <div className="heading">Online Payment</div>
            <div>
              <input
                type="radio"
                name="paymentmethod"
                value={"online-payment"}
                checked={selectedOption === "online-payment"}
                onChange={handleOptionChange}
              ></input>
            </div>
          </div>
          <div className="payment-main-container">
            <div className="heading">
              Bank Payment{" "}
              {bankPaymentUploadStatus === "success" ? (
                <GrStatusGood className="bank-payment-receipt-upload-success" />
              ) : bankPaymentUploadStatus === "failed" ? (
                <IoWarning className="bank-payment-receipt-upload-failed" />
              ) : null}
            </div>
            <div>
              <input
                type="radio"
                value={"bank-payment"}
                name="paymentmethod"
                checked={selectedOption === "bank-payment"}
                onClick={() => {
                  setOpenPopUp(true);
                }}
                onChange={handleOptionChange}
              ></input>
            </div>
          </div>
        </div>
        <button className="payment-button">Pay Rs.{price}</button>
      </div>
    </div>
  );
};

export default ChapterEnrollScreen;
