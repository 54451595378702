import { LuUpload } from "react-icons/lu";

const CustomFile = ({
  labelClassName,
  labelStyle,
  label,
  flex,
  onChange,
  fieldClassName,
  fileName,
  error,
  placeholder,
}) => {
  return (
    <div className="custom-input-container" style={{ flex: flex }}>
      <span>
        <label
          style={labelStyle}
          className={"custom-text-label " + labelClassName || ""}
        >
          {label}
        </label>
      </span>
      <div style={{ position: "relative" }}>
        <label
          className={"custom-file-field " + fieldClassName || ""}
          style={fileName ? { color: "black" } : {}}
        >
          <span>
            {fileName ? fileName : placeholder ? placeholder : "Upload Image"}
          </span>
          <LuUpload />
          <input
            name={"Field"}
            style={{ display: "none" }}
            type={"file"}
            onChange={(e) => {
              onChange(e);
            }}
          ></input>
        </label>
      </div>
      <span className="form-error-message">{error}</span>
    </div>
  );
};

export default CustomFile;
