import { useState } from "react";
import AdminStudentChapters from "./chapters/AdminStudentChapters";
import AdminStudentPayment from "./payments/AdminStudentPayment";
import AdminStudentOverview from "./overview/AdminStudentOverview";
import "./AdminStudentContent.css";

const AdminStudentContent = () => {
  const [selectedTab, setSelectedTab] = useState("chapters");
  const [openAddUnitPopup, setOpenAddUnitPopup] = useState(false);
  const [openAddExamPopup, setOpenAddExamPopup] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "20px",
      }}
    >
      <div className="admin-all-chapter-content-heading">Chapters</div>
      {/* Chapter Data */}
      <div className="admin-all-chapter-content-chapter-details-container">
        <div className="admin-all-student-content-chapter-data">
          <div>First Name: Anatomy</div>
          <div>School: Mu/ Vidyananda College</div>
          <div>Mobile: 0771234567</div>
          <div>Last Name: Anatomy</div>
          <div>Student ID: S001</div>
          <div>Mail: thanosan1999@gmail.com</div>
        </div>
      </div>

      {/* Chapter Switch Tab */}
      <div className="admin-all-chapter-content-tab">
        <div
          className={
            selectedTab === "chapters"
              ? "admin-all-chapter-content-tab-active"
              : null
          }
          onClick={() => {
            setSelectedTab("chapters");
          }}
        >
          Chapters
        </div>
        <div
          className={
            selectedTab === "payments"
              ? "admin-all-chapter-content-tab-active"
              : null
          }
          onClick={() => {
            setSelectedTab("payments");
          }}
        >
          Payments
        </div>
        <div
          className={
            selectedTab === "overview"
              ? "admin-all-chapter-content-tab-active"
              : null
          }
          onClick={() => {
            setSelectedTab("overview");
          }}
        >
          Overview
        </div>
        <div style={{ flex: 1, cursor: "default" }}>&nbsp;</div>
      </div>

      {/* Units or Exams View */}
      <div style={{ flex: "1" }} className="admin-chapter-content-container">
        {selectedTab === "chapters" ? (
          <AdminStudentChapters />
        ) : selectedTab === "payments" ? (
          <AdminStudentPayment />
        ) : (
          <AdminStudentOverview />
        )}
      </div>
    </div>
  );
};
export default AdminStudentContent;
