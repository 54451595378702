import React, { useEffect, useRef } from "react";
import "./CustomPopup.css"; // Import your CSS file for styling

function CustomPopup({ isOpen, onClose, children, style, closeButtonStyle }) {
  const popupRef = useRef(null);

  // Close the popup when clicking outside of the popup content
  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Add event listener when the component mounts
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Remove event listener when the component unmounts or isOpen changes
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  // Conditionally render the popup based on the 'isOpen' prop
  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-layout">
        <div className="popup-content" style={style} ref={popupRef}>
          <span
            className="close-button"
            onClick={onClose}
            style={closeButtonStyle}
          >
            &times;
          </span>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CustomPopup;