import "./AdminStudentPayment.css";

const AdminStudentPayment = () => {
  return (
    <div>
      <div>AdminStudentPayment</div>
    </div>
  );
};

export default AdminStudentPayment;
