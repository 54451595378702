import { Link } from "react-router-dom";
import "./ChapterContentUnits.css";
import ChapterCard from "../../../../components/ChapterCard/ChapterCard";
import DemoPagination from "../../../../components/DemoPagination/DemoPagination";
import { AllChaptersData } from "../../AllChapters/AllChaptersData";

const ChapterContentUnits = ({ chapterId, enrollButton }) => {
  return (
    <div>
      <div className="ul-all-chapters-container">
        {AllChaptersData.map((chapter, index) => {
          return (
            <ChapterCard
              nameEnglish={chapter.chapterEnglish}
              nameTamil={chapter.chapterTamil}
              chapterHeader={"Unit " + chapter.chapterNumber}
              units={chapter.units}
              exams={chapter.exams}
              key={index}
              image={chapter.image}
              type={"unit"}
            ></ChapterCard>
          );
        })}
        {enrollButton === null ? null : (
          <div className="enroll-button-container">{enrollButton}</div>
        )}
      </div>
      <DemoPagination />
    </div>
  );
};

export default ChapterContentUnits;
