import { useState } from "react";
import "./AdminAllUnits.css";
import { CellWidths, Data, Headers, RowAlignments } from "./TableConfig";
import PopUp from "../../../../../components/Dashboard/PopUp/PopUp";
import {
  deleteButton,
  editButton,
} from "../../../../../layouts/dashboardLayout/DashboardLayout";
import CustomTable from "../../../../../components/Dashboard/CustomTable/CustomTable";
import CustomPopup from "../../../../../components/Dashboard/CustomPopup/CustomPopup";
import AddNewUnit from "./AddUnits/AddNewUnit";
import { MdOutlineOpenInNew } from "react-icons/md";

const AdminAllUnits = () => {
  const onDelete = () => {
    alert("Deleted");
  };
  const [openPopup, setOpenPopup] = useState(false);

  const data =
    Data.length > 0 &&
    Data.map((element, index) => {
      return {
        tableData: [
          element.unitId,
          element.name,
          <a
            href={element.url}
            className="admin-exam-unit-open-handle"
            target="_blank"
          >
            {element.url}
            <MdOutlineOpenInNew />
          </a>,
          element.duration,
          <>
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={(event) => {
                setOpenPopup(true);
              }}
            >
              {editButton}
            </button>
            <PopUp
              message="Do you want to delete this Unit ?"
              onYes={[
                () => {
                  onDelete(element.unitId);
                },
              ]}
              buttonText={deleteButton}
              className="dashboard-form-delete-button"
            />
          </>,
        ],
      };
    });

  return (
    <div>
      {Data && Data.length > 0 ? (
        <CustomTable
          tableHead={Headers}
          tableData={data}
          cellWidths={CellWidths}
          rowAlignments={RowAlignments}
        />
      ) : (
        <div>Empty</div>
      )}
      <CustomPopup
        closeButtonStyle={{ color: "white" }}
        isOpen={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        style={{
          overflow: "auto",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <AddNewUnit
          title={"Update Unit"}
          buttontext={"Update Unit"}
          mode="update"
        />
      </CustomPopup>
    </div>
  );
};
export default AdminAllUnits;
