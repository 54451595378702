import { useState } from "react";
import CardTable from "../../../../components/CardTable/CardTable";
import CustomPopup from "../../../../components/Dashboard/CustomPopup/CustomPopup";
import PopUp from "../../../../components/Dashboard/PopUp/PopUp";
import {
  deleteButton,
  editButton,
} from "../../../../layouts/dashboardLayout/DashboardLayout";
import "./AdminAllChapters.css";
import { AdminAllChaptersData } from "./AdminAllChaptersData";
import { headerData, caption } from "./TableConfig";
import AddNewChapter from "./AddNewChapter/AddNewChapter";

const AdminAllChapters = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const onDelete = () => {
    alert("Deleted");
  };
  const [mode, setMode] = useState("add");
  const tableData = Array.isArray(AdminAllChaptersData)
    ? AdminAllChaptersData.map((data, index) => {
        return {
          tableViewInfo: [
            data.chapterId,
            data.unitName,
            data.numberOfUnits,
            data.numberOfExams,
            <>
              <button
                style={{
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setMode("update");
                  setOpenPopup(true);
                }}
              >
                {editButton}
              </button>
              {data.numberOfUnits === 0 && data.numberOfExams === 0 ? (
                <PopUp
                  message="Do you want to delete this Chapter ?"
                  onYes={[
                    () => {
                      onDelete(data._id);
                    },
                  ]}
                  buttonText={deleteButton}
                  className="dashboard-form-delete-button"
                />
              ) : (
                <PopUp
                  message="Please remove all the units and exams in order to delete the chapter."
                  onYes={[() => {}]}
                  hideYes={true}
                  heading = {"Warning"}
                  noButtonText="Close"
                  buttonText={deleteButton}
                  type="warning"
                  className="dashboard-form-delete-button"
                />
              )}
            </>,
          ],
          onClickTr: `/dashboard/admin/chapters/:chapterID`,
          id: index,
        };
      })
    : [];
  return (
    <div className="admin-all-chapters-container">
      <div className="admin-all-chapters-header">
        <div className="admin-main-page-title">Chapters</div>
        <button
          className="admin-page-button"
          onClick={() => {
            setMode("add");
            setOpenPopup(true);
          }}
        >
          New Chapter
        </button>
      </div>
      <div style={{ overflow: "auto" }}>
        <CardTable
          caption={caption}
          headerData={headerData}
          tableData={tableData}
          disableRowClick={false}
        />
      </div>
      <CustomPopup
        closeButtonStyle={{ color: "white" }}
        isOpen={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        style={{
          overflow: "auto",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <AddNewChapter
          title={mode === "update" ? "Update Chapter" : "Add Chapter"}
          buttontext={mode === "update" ? "Update Chapter" : "Add Chapter"}
        />
      </CustomPopup>
    </div>
  );
};
export default AdminAllChapters;
