// Navbar.js
import React, { useState } from "react";
import "./Footer.css";

const Footer = () => {
  const scrollToSection = (id) => {
    // alert(`Navigating to section ${id}`);
    const element = document.getElementById(id);
    if (element) {
      // alert("Scrolling to section");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer>
      <div class="footer-container">
        <section class="footer-about">
          <h2 className="footer-company-name">BIO WORLD</h2>
          <p>
            We are committed to providing the best service and products to our
            customers. Our goal is to exceed expectations in every way.
          </p>
        </section>
        <section class="footer-links">
          <h6>Quick Links</h6>
          <ul>
            <li>
              <a
                onClick={() => {
                  scrollToSection("home-meet-tutor");
                }}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  scrollToSection("home-our-services");
                }}
              >
                Courses
              </a>
            </li>
            <li
              onClick={() => {
                scrollToSection("home-contact-us");
              }}
            >
              <a>Contact us</a>
            </li>
          </ul>
        </section>
        <section class="footer-contact">
          <h6>Contact Us</h6>
          <address>
            <ul>
              <li>
                {" "}
                123 Main Street
                <br />
                Hometown, Country
                <br />
              </li>
              <li>
                Phone: (123) 456-7890
                <br />
              </li>
              <li>
                Email: <a href="mailto:info@example.com">info@example.com</a>
              </li>
            </ul>
          </address>
        </section>
        <section class="footer-social">
          <h6>Follow Us</h6>
          <ul>
            <a href="https://facebook.com" target="_blank">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://twitter.com" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://linkedin.com" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          </ul>
        </section>
      </div>
      <div class="footer-bottom">
        <p>&copy; 2024 Bio World. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
