import React, { useState, useEffect } from "react";
import "./Timer.css";
const Timer = ({ minutes, onEnd }) => {
  // in seconds
  const [time, setTime] = useState(minutes * 60);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const countdown = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdown);
          onEnd();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    const percent = (time / (minutes * 60)) * 100;
    setPercentage((prevPercentage) => 100 - Math.floor(percent));
  }, [time]);

  const formatTime = (seconds) => {
    const getHours = Math.floor(seconds / 3600);
    const getMinutes = Math.floor((seconds % 3600) / 60);
    const getSeconds = seconds % 60;
    return `${String(getHours).padStart(2, "0")}:${String(getMinutes).padStart(
      2,
      "0"
    )}:${String(getSeconds).padStart(2, "0")}`;
  };

  return (
    <>
      <div
        className="timer-container-web"
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ "--value": percentage }}
        timer={formatTime(time)}
      ></div>
      <div className="timer-container-mobile">{formatTime(time)}</div>
    </>
  );
};

export default Timer;
