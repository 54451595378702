import "./AdminStudentOverview.css"

const AdminStudentOverview = () => {
  return (
    <div>
      <div>AdminStudentOverview</div>
    </div>
  );
};

export default AdminStudentOverview;
