export const AdminAllStudentsData = [
    {
      studentId: "STU001",
      name: "Nimal Perera",
      mobile: "0771234567",
      email: "nimal.perera@example.com",
      school: "Royal College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU002",
      name: "Samanthi Silva",
      mobile: "0752345678",
      email: "samanthi.silva@example.com",
      school: "Ladies' College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU003",
      name: "Kamal Fernando",
      mobile: "0773456789",
      email: "kamal.fernando@example.com",
      school: "Trinity College, Kandy",
      address: "Kandy, Sri Lanka"
    },
    {
      studentId: "STU004",
      name: "Sunil Wijesinghe",
      mobile: "0754567890",
      email: "sunil.wijesinghe@example.com",
      school: "St. Joseph's College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU005",
      name: "Dilani Jayawardena",
      mobile: "0775678901",
      email: "dilani.jayawardena@example.com",
      school: "Musaeus College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU006",
      name: "Ruwan Dissanayake",
      mobile: "0756789012",
      email: "ruwan.dissanayake@example.com",
      school: "Ananda College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU007",
      name: "Iresha Ratnayake",
      mobile: "0777890123",
      email: "iresha.ratnayake@example.com",
      school: "Hillwood College, Kandy",
      address: "Kandy, Sri Lanka"
    },
    {
      studentId: "STU008",
      name: "Lakmal Gunawardena",
      mobile: "0758901234",
      email: "lakmal.gunawardena@example.com",
      school: "Nalanda College, Colombo",
      address: "Colombo, Sri Lanka"
    },
    {
      studentId: "STU009",
      name: "Tharanga Weerasinghe",
      mobile: "0779012345",
      email: "tharanga.weerasinghe@example.com",
      school: "St. Anthony's College, Kandy",
      address: "Kandy, Sri Lanka"
    },
    {
      studentId: "STU010",
      name: "Madhavi Karunaratne",
      mobile: "0750123456",
      email: "madhavi.karunaratne@example.com",
      school: "Visakha Vidyalaya, Colombo",
      address: "Colombo, Sri Lanka"
    }
  ];
  