import React from "react";
import "./CustomTable.css";

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const CustomTable = ({
  tableHead,
  tableData,
  cellWidths,
  onClick,
  rowAlignments,
}) => {
  console.log(tableData);
  return (
    <div className="custom-table-container">
      <table className="custom-table">
        {tableHead && (
          <thead className="custom-table-head">
            <tr>
              {tableHead.map((header, index) => (
                <th
                  key={index}
                  style={{
                    minWidth: cellWidths && cellWidths[index]?.minWidth,
                    maxWidth: cellWidths && cellWidths[index]?.maxWidth,
                    width: cellWidths && cellWidths[index]?.width,
                    textAlign: rowAlignments && rowAlignments[index],
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {tableData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`custom-table-tr`}
              onClick={row?.onClickTr}
            >
              {row?.tableData?.map((cell, cellIndex) => (
                <td
                  className={`${cell + tableHead[cellIndex]} ${
                    tableHead[cellIndex]
                  }`}
                  key={cellIndex}
                  data-label={
                    typeof cell !== "object" ? tableHead[cellIndex] + ":" : ""
                  } // Add data-label attribute
                  // onClick={typeof cell !== 'object' ? () => onClick() : undefined}
                  style={{
                    minWidth: cellWidths && cellWidths[cellIndex]?.minWidth,
                    maxWidth: cellWidths && cellWidths[cellIndex]?.maxWidth,
                    width: cellWidths && cellWidths[cellIndex]?.width,
                    textAlign: rowAlignments && rowAlignments[cellIndex],
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
