export const AdminAllChaptersData = [
  {
    chapterId: "C001",
    unitName: "Introduction to Cellular Biology",
    numberOfUnits: 5,
    numberOfExams: 2,
  },
  {
    chapterId: "C002",
    unitName: "Molecular Genetics Basics",
    numberOfUnits: 0,
    numberOfExams: 0,
  },
  {
    chapterId: "C003",
    unitName: "Human Physiology and Anatomy",
    numberOfUnits: 7,
    numberOfExams: 2,
  },
  {
    chapterId: "C004",
    unitName: "Plant Structure and Function",
    numberOfUnits: 5,
    numberOfExams: 2,
  },
  {
    chapterId: "C005",
    unitName: "Principles of Evolutionary Biology",
    numberOfUnits: 0,
    numberOfExams: 0,
  },
  {
    chapterId: "C006",
    unitName: "Ecology and Environmental Biology",
    numberOfUnits: 4,
    numberOfExams: 1,
  },
  {
    chapterId: "C007",
    unitName: "Biotechnology and Its Applications",
    numberOfUnits: 5,
    numberOfExams: 2,
  },
];
