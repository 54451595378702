// Navbar.js
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import Logo from "../../../assets/Logo.jpg";
import { Link, useNavigate } from "react-router-dom";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const handleClick = () => {
    alert("Button clicked!");
  };
  const navigate = useNavigate();
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (id) => {
    // alert(`Navigating to section ${id}`);
    const element = document.getElementById(id);
    if (element) {
      // alert("Scrolling to section");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 950) {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
    };
    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const links = [
    {
      href: "/",
      text: "Home",
    },
    {
      text: "Courses",
      onClick: () => {
        scrollToSection("home-our-services");
      },
    },
    {
      text: "About us",
      onClick: () => {
        scrollToSection("home-meet-tutor");
      },
    },
    {
      text: "Contact us",
      onClick: () => {
        scrollToSection("home-contact-us");
      },
    },
  ];
  return (
    <header>
      <nav className="nav-container">
        <div className="navbar-container">
          <div className="logo-container">
            <a href="/" className="logo-link">
              <img src={Logo} alt="Bio World Logo" className="logo-image" />
              <h3 className="logo-text">BIO WORLD</h3>
            </a>
          </div>

          {menuOpen && (
            <ul>
              {links.map((link, index) => (
                <li key={index} className="nav-menu-item">
                  <Link
                    to={link?.href ? link.href : ""}
                    onClick={link?.onClick ? link?.onClick : null}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}

              <li></li>
              <li>
                <CustomButton
                  label="Sign In"
                  onClick={() => {
                    navigate("/dashboard/user");
                  }}
                  variant="outlined"
                  disabled={false}
                />
                <CustomButton
                  label="Get Started"
                  onClick={() => {
                    navigate("/dashboard/user");
                  }}
                  variant="normal"
                  disabled={false}
                />
              </li>
            </ul>
          )}
          <div className="menu" onClick={handleMenuClick}>
            {!menuOpen ? (
              <i class="fa-solid fa-bars"></i>
            ) : (
              <i class="fa-solid fa-xmark"></i>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
