import { Link } from "react-router-dom";
import UserLayoutSearchHeader from "../../../components/UserLayout/UserLayoutSearchHeader/UserLayoutSearchHeader";
import "./EnrolledChapters.css";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import ChapterCard from "../../../components/ChapterCard/ChapterCard";
import { AllChaptersData } from "../AllChapters/AllChaptersData";
import DemoPagination from "../../../components/DemoPagination/DemoPagination";
import { EnrolledChaptersData } from "./EnrolledChapterData";
const EnrolledChapters = () => {
  return (
    <section id="user-all-chapters">
      <UserLayoutSearchHeader
        leftSide={
          <span className="units-exams-button-container">
            <span className="units-exam-button-set">
              <Link className={"home-ec-button "} to="/dashboard/user">
                All Chapters
              </Link>
              <Link className={"home-ec-button ec-button-active"}>
                Enrolled Chapters
              </Link>
            </span>
          </span>
        }
        rightSide={"Search for a chapter"}
      />
      <div className="ul-all-chapters-container">
        {EnrolledChaptersData.map((chapter, index) => {
          {
            /* only for demo */
          }
          if (chapter.isEnrolled === true) {
            return (
              <ChapterCard
                nameEnglish={chapter.chapterEnglish}
                nameTamil={chapter.chapterTamil}
                chapterHeader={"Chapter " + chapter.chapterNumber}
                units={chapter.units}
                exams={chapter.exams}
                key={index}
                image={chapter.image}
                isEnrolled={true}
                id={chapter.id || "sample-chapter-id"}
              ></ChapterCard>
            );
          } else {
            return null;
          }
        })}
      </div>
      <DemoPagination />
    </section>
  );
};

export default EnrolledChapters;
