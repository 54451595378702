import React, { useState } from "react";
import Landing from "../../containers/simpleLayout/Home/Landing/Landing";
import Services from "../../containers/simpleLayout/Home/Services/Services";
import Tutor from "../../containers/simpleLayout/Home/Tutor/Tutor";
import ContactUs from "../../containers/simpleLayout/Home/ContactUs/ContactUs";

const Home = () => {
  return (
    <>
      <Landing />
      <Services />
      <Tutor />
      <ContactUs />
    </>
  );
};

export default Home;
