import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import Logo from "../../../assets/Logo_white.png";
import sidebar from "./config";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const containerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1400 && window.innerWidth > 400) {
        setIsOpen(false);
        document.addEventListener("mousedown", handleClickOutside);
      } else if (window.innerWidth < 400) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(location.pathname);
  }, [location.pathname]);

  return (
    <aside className="sidebar-container" ref={containerRef}>
      {isOpen && (
        <div className="sidebar-container-section">
          <div className="sidebar-logo-container">
            <img src={Logo} alt="logo" />
            <div className="sidebar-logo-text">Bio World</div>
          </div>
          <nav>
            <ul>
              {sidebar.map((item, index) => (
                <Link to={item.path} key={index}>
                  <li
                    className={
                      location.pathname === item.path ? "sidebar-active" : ""
                    }
                  >
                    <span>{item.icon}</span>
                    {item.title}
                  </li>
                </Link>
              ))}
            </ul>
          </nav>
          <div className="account-container">
            <a href="/dashboard/profile">
              <div>
                <img
                  src={
                    "https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?w=826&t=st=1718746671~exp=1718747271~hmac=c31f22621012b5eb3d26934b901fc0ed0b3393d663f987b82c382172925e4546"
                  }
                  alt="logo"
                />
              </div>
              <div>
                <p>Vijay Antony</p>
                <p>Admin</p>
              </div>
            </a>
          </div>
        </div>
      )}
      <div className="menu" onClick={handleMenuClick}>
        {!isOpen ? (
          <i className="fa-solid fa-bars"></i>
        ) : (
          <i className="fa-solid fa-xmark"></i>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
