import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import "./DemoPagination.css";
const DemoPagination = () => {
  return (
    <div className="sample-pagination">
      <button style={{ background: "#4195AF" }}>
        <IoIosArrowBack style={{ strokeWidth: "43px" }} />
      </button>
      <button>1</button>
      <button style={{ background: "#4195AF" }}>2</button>
      <button>
        <IoIosArrowForward style={{ strokeWidth: "43px" }} />
      </button>
    </div>
  );
};

export default DemoPagination;
