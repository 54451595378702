import "./Landing.css";
import LandingSvg from "../../../../assets/simpleLayout/landing/Landing.svg";
import CircleDesign from "../../../../components/CircleDesign/CircleDesign";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
const Landing = () => {
  return (
    <section id="home-landing">
      <div className="home-landing-container">
        <div className="home-landing-left-container">
          <h1 className="home-landing-heading-container">
            <span className="heading-color-black">Up Your </span>
            <span className="heading-color-blue">Skills </span>
            <br></br>
            <span className="heading-color-black">To </span>
            <span className="heading-color-blue">Advance </span>
            <span className="heading-color-black">Your </span>
            <br></br>
            <span className="heading-color-blue">Career </span>
            <span className="heading-color-black">Path </span>
          </h1>
          <div className="home-landing-desc-container">
            Learn UI-UX Design skills with weekend UX . The latest online{" "}
            <br></br>
            learning system and material that help your knowledge growing.
          </div>
          {/* <CustomButton label={"Get Started"} variant="normal"></CustomButton> */}
          <Link className="home-landing-button" to="/dashboard/user">
            Get Started
          </Link>
        </div>

        <img
          src={LandingSvg}
          draggable={false}
          alt="landing-pic"
          className="home-landing-image not-selectable"
        />
      </div>
      <div className="radial-circle not-selectable"></div>

      <CircleDesign
        className={"home-landing-circle-1 not-selectable"}
      ></CircleDesign>

      <CircleDesign
        className={"home-landing-circle-2 not-selectable"}
      ></CircleDesign>

      <div className="home-landing-circle-3"></div>
    </section>
  );
};
export default Landing;
