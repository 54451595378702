import React from 'react';
import './CustomButton.css'

const CustomButton = ({ label, onClick,disabled,variant = 'normal',styles }) => {
    const buttonClassName = `button ${variant}`;
  
    return (
      <button className={buttonClassName} onClick={onClick} style={styles}
      disabled={disabled}>
        {label}
      </button>
    );
  };

export default CustomButton;
