const headerData = [
  {
    name: "ID",
    minWidth: "50px",
    maxWidth: "auto",
    width: "5vw",
    textAlign: "center",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Name",
    minWidth: "50px",
    maxWidth: "auto",
    width: "200px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Mobile",
    minWidth: "50px",
    maxWidth: "auto",
    width: "100px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "Email",
    minWidth: "20px",
    maxWidth: "auto",
    width: "50px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
  {
    name: "School",
    minWidth: "50px",
    maxWidth: "auto",
    width: "200px",
    textAlign: "left",
    color: "white",
    fontSize: "var(--font-14-px)",
    tdStyle: {
      fontSize: "var(--font-14-px)",
    },
  },
];

const caption = "";

export { headerData, caption };
