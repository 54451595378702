import { Link } from "react-router-dom";
import "./ExamView.css";
import { useEffect, useState } from "react";
import Timer from "../../../components/Timer/Timer";
import Navigation from "../../../components/Navigation/Navigation";
const ExamView = () => {
  const [startExam, setStartExam] = useState(false);
  const submitQuiz = () => {
    alert("Quiz submitted");
  };
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prev) => (prev < 100 ? prev + 1 : 0));
    }, 100); // adjust the interval as needed

    return () => clearInterval(interval);
  }, []);
  return (
    <section id="exam-view-user">
      <Navigation
        config={[
          {
            name: "Enrolled Chapters",
            link: "/dashboard/user/chapters/enrolled",
          },
          {
            name: "Chapter 1",
            link: "/dashboard/user/chapters/100",
          },
          {
            name: "Exams",
          },
          {
            name: "E001",
          },
        ]}
      />

      {startExam === false ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="exam-view-pre-screen-container">
            <div className="header">
              <div className="heading">Chapter 1</div>
              <div className="heading">உயிரின் இரசாயண கால அடிப்படை</div>
              <div className="heading">Chemical & Cellular Basic of life</div>
            </div>
            <div className="body">
              <div className="exam-desc">
                The exam will be automatically submitted and closed after the
                duration is completed. <br></br>However, you can redo the exam
                unlimited times.
                <br></br>Are you sure you want to begin this exam?
              </div>
              <div className="duration">Duration : 1 hour 30 minutes</div>
              <Link
                onClick={() => {
                  setStartExam(true);
                }}
                className="start-button"
              >
                Start Exam
              </Link>
              {/* <Link className="back-button" to="/dashboard/user/chapters/abc">
                Go Back
              </Link> */}
            </div>
          </div>
        </div>
      ) : (
        <div className="exam-view">
          <div className="header">
            <div className="heading">Chapter 1</div>

            <div className="heading-tamil">உயிரின் இரசாயண கால அடிப்படை</div>
            <div className="heading-english">
              Chemical & Cellular Basic of life
            </div>
            <div className="exam-duration">Duration: 1h 30m</div>

            

            <div className="exam-timer">
              <Timer minutes={10} onEnd={submitQuiz}></Timer>
            </div>
          </div>
          <div className="body">
            <iframe
              src="https://forms.gle/R2BbRHpuFvN6LE4u9"
              className="body-exam-iframe"
            >
              Loading...
            </iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default ExamView;
