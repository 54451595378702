import { useState } from "react";
import "./AddNewChapter.css";
// import { PreviewImage } from "../../functions/FileFunctions";
import NoImage from "../../../../../assets/noimage.png";
import CustomText from "../../../../../components/FormComponents/Text/CustomText";
import CustomFile from "../../../../../components/FormComponents/CustomImageFile/CustomFile";
import useHandleFormData from "../../../../../hooks/useHandleFormData";
import { PreviewImage } from "../../../../../functions/FileFunctions";
const AddNewChapter = ({ title, buttontext }) => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = PreviewImage("file", file);
      setFormData((prevData) => ({
        ...prevData,
        imageUrl: imageUrl, // Add this line to store the image URL in formData
      }));
    }
  };
  return (
    <div className="add-new-chapter-container">
      <div className="header">{title}</div>
      <div className="body">
        <CustomText
          label={"Name"}
          labelClassName={"bank-payment-label"}
          flex={"100%"}
          onChange={(e) => {
            handleChange(e, "name");
          }}
          fieldClassName={"add-new-chapter-field "}
          error={errors?.name}
          value={formData.name}
          placeholder={"Enter Chapter Name"}
        ></CustomText>
        <CustomFile
          label={"Thumbnail"}
          labelClassName={"bank-payment-label"}
          flex={"100%"}
          onChange={(e) => {
            handleChange(e, "image");
            handleImageChange(e);
          }}
          fieldClassName={"add-new-chapter-field "}
          error={errors?.image}
          value={formData.image}
          fileName={formData?.image?.name}
        ></CustomFile>
        <img
          src={formData?.imageUrl ? formData?.imageUrl : NoImage} // Use imageUrl from formData
          alt="img-preview"
          className="add-new-chapter-image-preview"
        ></img>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="add-chapter-button">{buttontext}</button>
        </div>
      </div>
    </div>
  );
};

export default AddNewChapter;
