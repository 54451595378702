const sidebar = [
    {
      title: 'Dashboard',
      path: '/dashboard/admin',
      icon: <i className="fa-solid fa-house"></i>
    },
    {
      title: 'Chapter',
      path: '/dashboard/admin/chapters',
      icon:<i class="fa-solid fa-graduation-cap"></i>
    },
    {
      title: 'Payment',
      path: '/dashboard/admin/payment',
      icon: <i class="fa-regular fa-credit-card"></i>
    },

    {
      title: 'Student',
      path: '/dashboard/admin/student',
      icon:<i class="fa-solid fa-chart-line"></i>
    },
    {
        title: 'Profile',
        path: '/dashboard/admin/profile',
        icon:<i class="fa-solid fa-id-card"></i>
      }
  ];

  export default sidebar