export function MinutesToHour(minutes) {
    // Calculate the number of hours
    let hours = Math.floor(minutes / 60);
    // Calculate the remaining minutes
    let remainingMinutes = minutes % 60;
  
    // Format the output
    let result = `${hours} Hour${hours !== 1 ? "s" : ""}`;
    if (remainingMinutes > 0) {
      result += ` ${remainingMinutes} Minute${remainingMinutes !== 1 ? "s" : ""}`;
    }
  
    return result;
  }