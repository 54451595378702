import "./ChapterCard.css";
import { GoDotFill } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const ChapterCard = ({
  nameTamil,
  nameEnglish,
  units,
  exams,
  chapterHeader,
  image,
  isEnrolled,
  type,
  id,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="chapter-card-container"
      onClick={() => {
        id && navigate("/dashboard/user/chapters/" + id);
      }}
    >
      <div className="chap-card-header-container">
        <div className="chapter-card-header-name"> {chapterHeader}</div>
        {isEnrolled ? (
          <div className="chapter-card-enrolled">
            <GoDotFill />
            <span>ENROLLED</span>
          </div>
        ) : null}
      </div>
      <div className="chapter-card-cover-container">
        <img
          src={image}
          alt="Chapter-cover"
          className="chapter-cover-image"
        ></img>
        {type === "unit" ? (
          <div className="chapter-card-video-overlay">
            <div className="video-play-button">
              <FaPlay />
            </div>
          </div>
        ) : null}
        {type === "unit" ? (
          <div className="chapter-card-duration-overlay">1:10:00</div>
        ) : null}
      </div>

      <div className="chapter-card-heading">{nameTamil}</div>
      <div className="chapter-card-heading">{nameEnglish}</div>

      {type === "unit" ? null : (
        <div className="chapter-card-footer">
          <div className="chapter-card-num-of-units">Units: {units}</div>
          <div className="chapter-card-num-of-exams">Exams: {exams}</div>
        </div>
      )}
    </div>
  );
};

export default ChapterCard;
