import React, { useState, useEffect } from "react";

import "./DeletePopUp.css";
import CustomPopup from "../CustomPopup/CustomPopup";
import CustomButton from "../../CustomButton/CustomButton";
function DeletePopUp({
  isOpenProp,
  closePopup,
  onclick,
  title,
  description,
  deleteButtonTitle,
}) {
  const handleClose = () => {
    closePopup();
  };

  return (
    <CustomPopup isOpen={isOpenProp} onClose={handleClose}>
      <div className="delete-popup">
        <div className="delete-popup-head">
          {title}
          {/* <hr/> */}
        </div>

        <div className="delete-popup-container">{description}</div>

        <div className="delete-popup-button-container">
          <div>
            <CustomButton
              label={deleteButtonTitle}
              onClick={onclick}
              variant="outlined"
              styles={{ color: "#D03636", boxShadow: "inset 0 0 0 1px red" }}
            />
          </div>
          <div>
            <CustomButton label={"Cancel"} onClick={handleClose} styles={{}} />
          </div>
        </div>
      </div>
    </CustomPopup>
  );
}

export default DeletePopUp;
