import { FaCopy, FaTrophy } from "react-icons/fa";
import CustomText from "../../../../components/FormComponents/Text/CustomText";
import useHandleFormData, {
  performValidation,
} from "../../../../hooks/useHandleFormData";
import { useDispatch } from "react-redux";
import { showToastMessage } from "../../../../redux/actions/toastActions";
import CustomFile from "../../../../components/FormComponents/CustomImageFile/CustomFile";

const BankPaymentPopup = ({ setBankPaymentUploadStatus, close }) => {
  const { formData, errors, handleChange, handleErrors, toast, setFormData } =
    useHandleFormData();
  const dispatch = useDispatch();
  const copyToClipboard = (text) => {
    try {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          dispatch(showToastMessage("Copied !", "successToast"));
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const handleSubmit = () => {
    const validationConfig = [
      {
        name: "name",
        validations: ["required"],
        label: "",
      },
      {
        name: "accountNumber",
        validations: ["required"],
        label: "",
      },
      {
        name: "bankName",
        validations: ["required"],
        label: "",
      },
      {
        name: "branch",
        validations: ["required"],
        label: "",
      },
      {
        name: "date",
        validations: ["required"],
        label: "",
      },
      {
        name: "pdf",
        type: "pdf",
        count: 1,
        validations: ["required"],
        label: "",
      },
    ];
    const validationResults = performValidation(validationConfig, formData);
    handleErrors(validationResults);
    if (Object.keys(validationResults).length === 0) {
      console.log(formData);
      let bodyData = {
        name: formData.name,
        accountNumber: formData.accountNumber,
        bankName: formData.bankName,
        branch: formData.branch,
        date: formData.date,
        pdf: formData.pdf,
      };
      try {
        toast("Submitted Successfully", "successToast");
        setBankPaymentUploadStatus("success");
        close();
        setFormData({
          name: "",
          accountNumber: "",
          bankName: "",
          branch: "",
          date: "",
          pdf: [],
        });
      } catch (err) {
        setBankPaymentUploadStatus("failed");
        toast("Oops! Something went wrong", "errorToast");
      }
    }
  };
  return (
    <div className="payment-popup-container">
      <div className="header">Bank Transfer Method</div>
      <div className="body">
        <div className="bank-data">
          <div className="heading">Bank Account Details</div>
          <p className="para">
            We allow bank transfer payment methods. Here are the account details
            for BioWorld. After making the payment, please fill out the form on
            the right side and submit the payment proof.
          </p>
          <p className="para">
            Once we receive your payment proof, we will process and approve your
            request within 24 hours. If you have any questions or need further
            assistance, please feel free to contact our support team.
          </p>
          <div className="bank-details">
            <table>
              <tr>
                <td>Account Holder Name</td>
                <td>
                  : Alan Antony{" "}
                  <FaCopy
                    className="bank-pay-copy"
                    onClick={() => {
                      copyToClipboard("Alan Antony");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Account Number </td>
                <td>
                  : 000123458787{" "}
                  <FaCopy
                    className="bank-pay-copy"
                    onClick={() => {
                      copyToClipboard("000123458787");
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>Bank Name</td>
                <td>: Bank of Ceylon</td>
              </tr>
              <tr>
                <td>Branch</td>
                <td>: Jaffna</td>
              </tr>
              <tr>
                <td>Amount</td>
                <td>: Rs.2000</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>: (Your Mobile Number)</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="bank-form">
          <CustomText
            label={"Account Holder Name"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "name");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.name}
            value={formData.name}
          ></CustomText>
          <CustomText
            label={"Account Number"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "accountNumber");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.accountNumber}
            value={formData.accountNumber}
          ></CustomText>
          <CustomText
            label={"Bank Name"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "bankName");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.bankName}
            value={formData.bankName}
          ></CustomText>
          <CustomText
            label={"Branch"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "branch");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.branch}
            value={formData.branch}
          ></CustomText>
          <CustomText
            label={"Date"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "date");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.date}
            value={formData.date}
            type={"date"}
          ></CustomText>
          <CustomFile
            placeholder={"Upload PDF"}
            label={"Payment Receipt"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "pdf");
            }}
            fieldClassName={"add-bank-payment-pdf "}
            error={errors?.pdf}
            value={formData.pdf}
            fileName={formData?.pdf?.name}
          ></CustomFile>

          {/* <CustomText
            label={"Payment Receipt"}
            labelClassName={"bank-payment-label"}
            flex={"100%"}
            onChange={(e) => {
              handleChange(e, "fullName");
            }}
            fieldClassName={"bank-payment-field"}
            error={errors?.fullName}
            value={formData.fullName}
          ></CustomText> */}
          <button
            className="bank-payment-proof-submit-button"
            onClick={handleSubmit}
          >
            Submit Payment Proof
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankPaymentPopup;
