import { useState } from "react";
import "./AdminChapterContent.css";
import { FaPlus } from "react-icons/fa";
import AdminAllUnits from "./AllUnits/AdminAllUnits";
import AdminAllExams from "./AllExams/AdminAllExams";
import CustomPopup from "../../../../components/Dashboard/CustomPopup/CustomPopup";
import AddNewUnit from "./AllUnits/AddUnits/AddNewUnit";
import AddNewExam from "./AllExams/AddExam/AddNewExam";
const AdminChapterContent = () => {
  const [selectedTab, setSelectedTab] = useState("Units");
  const [openAddUnitPopup, setOpenAddUnitPopup] = useState(false);
  const [openAddExamPopup, setOpenAddExamPopup] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "20px",
      }}
    >
      <div className="admin-all-chapter-content-heading">Chapters</div>
      {/* Chapter Data */}
      <div className="admin-all-chapter-content-chapter-details-container">
        <div className="admin-all-chapter-content-chapter-data">
          <div>Chapter Name: Anatomy</div>
          <div>Chapter ID: BioAna01</div>
          <div>Units: 12</div>
        </div>
        <div className="admin-all-chapter-content-button-container">
          <div className="admin-all-chapter-content-add-chapter-content-button">
            <button
              className="add-chapter-content-button-admin"
              onClick={() => {
                setOpenAddUnitPopup(true);
              }}
            >
              <span className="text">Units</span>
              <span className="plus">
                <FaPlus />
              </span>
            </button>
          </div>
          <div className="admin-all-chapter-content-add-exam-button">
            <button
              className="add-exams-button-admin"
              onClick={() => {
                setOpenAddExamPopup(true);
              }}
            >
              <span className="text">Exams</span>
              <span className="plus">
                <FaPlus />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* Chapter Switch Tab */}
      <div className="admin-all-chapter-content-tab">
        <div
          className={
            selectedTab === "Units"
              ? "admin-all-chapter-content-tab-active"
              : null
          }
          onClick={() => {
            setSelectedTab("Units");
          }}
        >
          Units
        </div>
        <div
          className={
            selectedTab === "Exams"
              ? "admin-all-chapter-content-tab-active"
              : null
          }
          onClick={() => {
            setSelectedTab("Exams");
          }}
        >
          Exams
        </div>
        <div style={{ flex: 1, cursor: "default" }}>&nbsp;</div>
      </div>
      {/* Units or Exams View */}
      <div style={{ flex: "1" }} className="admin-chapter-content-container">
        {selectedTab === "Units" ? <AdminAllUnits /> : <AdminAllExams />}
      </div>
      {/* Popup - Add Unit */}
      <CustomPopup
        closeButtonStyle={{ color: "white" }}
        isOpen={openAddUnitPopup}
        onClose={() => {
          setOpenAddUnitPopup(false);
        }}
        style={{
          overflow: "auto",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <AddNewUnit title={"Add Unit"} buttontext={"Add Unit"} mode="add" />
      </CustomPopup>
      {/* Popup - Add Exam */}
      <CustomPopup
        closeButtonStyle={{ color: "white" }}
        isOpen={openAddExamPopup}
        onClose={() => {
          setOpenAddExamPopup(false);
        }}
        style={{
          overflow: "auto",
          padding: "0px",
          backgroundColor: "transparent",
        }}
      >
        <AddNewExam title={"Add Exam"} buttontext={"Add Exam"} mode="add" />
      </CustomPopup>
    </div>
  );
};
export default AdminChapterContent;
